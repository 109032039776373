//Libraries
import React, { FunctionComponent } from 'react'
import { graphql } from 'gatsby'
import { Helmet } from 'react-helmet'

//Context
import { FontSizeProvider } from '../context/fontSizeContext'

//Types
import { AboutPageTypes } from './z_page-types'

//Components
import Header from '../components/header/header'
import Footer from '../components/footer/footer'
import Layout from '../components/layout/layout'
import PageWelcome from '../components/header/pageWelcome'
import IconWithText from '../components/free/iconWithText'
import PageBackground from '../components/free/pageBackground'

const About: FunctionComponent<AboutPageTypes> = ({ data }) => {
  const { markdownRemark } = data
  const { html, frontmatter } = markdownRemark

  return (
    <FontSizeProvider>
        <Helmet htmlAttributes={{lang: 'en'}}>
          <meta charSet="utf-8" />
          <title>OOA - About</title>
          <link rel="canonical" href="https://ooa.csi.edu/about/" />
          <meta
            name="description"
            content="The College of Southern Idaho (CSI) Office on Aging (OOA) serves as Idaho's Area IV Agency on Aging (AAA). We help seniors in the eight rural counties of south central Idaho, commonly referred to as the Magic Valley"
          />
        </Helmet>

      <Header />

      <Layout>
        <PageWelcome
          img={frontmatter.img}
          imgAltText="About banner image"
          title={frontmatter.title}
        />

        <article dangerouslySetInnerHTML={{ __html: html }}></article>

        <article>
          {markdownRemark.frontmatter.contacts.map((contact, index) => (
            <span key={index}>
              <h3>{contact.name}</h3>
              <IconWithText
                icon="ri-phone-line"
                text={[contact.position]}
                tels={[contact.phone]}
                emails={[contact.email]}
              />
            </span>
          ))}
        </article>
      </Layout>
      <Footer />
      <PageBackground />
    </FontSizeProvider>
  )
}

export default About

export const aboutQuery = graphql`
  query About {
    markdownRemark(frontmatter: { template_key: { eq: "about-page" } }) {
      html
      frontmatter {
        title
        img {
          childImageSharp {
            fluid(maxWidth: 1700, quality: 100) {
              ...GatsbyImageSharpFluid
            }
          }
        }
        contacts {
          email
          name
          phone
          position
        }
      }
    }
  }
`
